<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="block-layout-footer">
    <div class="block-layout-footer__inner">
      <div class="block-layout-footer__column">
        <ul class="block-layout-footer__list">
          <li
            v-for="(link, index) of toolLinks"
            :key="`toollink-${link.url}-${index}`"
            class="block-layout-footer__list-item"
          >
            <UtilRouteLink :link="link.url">
              {{ link.label }}
            </UtilRouteLink>
          </li>
        </ul>

        <div class="block-layout-footer__information">
          <div class="block-layout-footer__information__column" v-html="promologicClaim" />
        </div>
      </div>

      <div class="block-layout-footer__column">
        <ul class="block-layout-footer__list">
          <li
            v-for="(link, index) of userLinks"
            :key="`userlink-${link.url}-${index}`"
            class="block-layout-footer__list-item"
          >
            <UtilRouteLink :link="link.url">
              {{ link.label }}
            </UtilRouteLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    borderRadius: {
        type: String,
        default: 'var(--b-radius--big)',
    },

    backgroundColor: {
        type: String,
        default: 'var(--c-white)',
    },

    textColor: {
        type: String,
        default: 'var(--c-black)',
    },

    linkColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    /*
      Content
    */
    toolLinks: {
        type: Array,
        default: () => [],
    },

    userLinks: {
        type: Array,
        default: () => [],
    },

    promologicLogo: {
        type: Object,
        default: () => ({
            src: '/images/logos/promologic-full.svg',
            alt: 'Promologic Logo',
            link: 'https://www.promologic.ch',
        }),
    },

    nuevaLogo: {
        type: Object,
        default: () => ({
            src: '/images/logos/nueva.svg',
            alt: 'Nueva Logo',
            link: 'https://www.nueva.ch',
        }),
    },

    promologicClaim: {
        type: String,
        default: '',
    },
});

const {
    backgroundColor,
    linkColor,
    textColor,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.block-layout-footer {
    @include wrapper-layout;

    width: 100%;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--h4);
    font-weight: var(--f-weight--500);
    line-height: normal;
}

.block-layout-footer__inner {
    @include grid-layout(1, 12, 12);
    @include fluid('padding-top', 60px, 40px, 70px, false);
    @include fluid('padding-bottom', 60px, 40px, 70px, false);

    --border-radius: v-bind(borderRadius);
    padding-right: 30px;
    padding-left: 30px;

    background-color: v-bind(backgroundColor);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    row-gap: 60px;

    @include tablet {
        padding-right: 0;
        padding-left: 0;
        row-gap: 0;
    }
}

.block-layout-footer__column {
    display: flex;
    flex-direction: column;
    row-gap: 60px;

    &:first-child {
        order: 2;

        @include tablet {
            order: 1;
            grid-column-end: span 7;
            grid-column-start: 2;
        }
    }

    &:last-child {
        order: 1;

        @include tablet {
            order: 2;
            grid-column-end: span 3;
        }
    }

    @include tablet {
        row-gap: 30px;
    }
}

.block-layout-footer__list {
    display: flex;
    flex-direction: column;
    color: v-bind(linkColor);
    row-gap: 20px;

    @include tablet {
        row-gap: 10px;
    }
}

.block-layout-footer__information {
    display: flex;
    flex-wrap: wrap;
    color: v-bind(textColor);
    column-gap: 17px;
    row-gap: 20px;
}
</style>
