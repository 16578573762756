<template>
  <div class="layout-default">
    <BlockLayoutHeaderDefault
      :logos="campaignData?.logos"
      :promo-link="campaignData?.landingPageUrl"
      :promo-link-text="campaignData?.landingpageButtonText"
      :links="campaignData?.headerLinks"
      :user-links="campaignData?.userLinks"
    />
    <suspense>
      <slot />
    </suspense>

    <BlockLayoutFooter
      :user-links="campaignData?.footer?.userLinks"
      :tool-links="campaignData?.footer?.toolLinks"
      :promologic-claim="campaignData?.footer?.promologicClaim"
    />
  </div>
</template>

<script setup>
const campaignData = inject('campaignData');
</script>

<style lang="scss" scoped>
.layout-default {
    padding-top: calc(var(--header-height) + 60px);
}
</style>
